export type IKeyValue = { key: string; value: string | null }
export type IKeyValueObj = { [key: string]: string | null }

export const removeQuotes = (str: string) => {
	return str.replace(/"/g, "")
}

export function getKey<T, K extends keyof T>(obj: T, key: K) {
	return obj[key]
}

export function isObjKey<T extends object>(key: any, obj: T): key is keyof T {
	return key in obj
}

export function singleTenantUrl(siteName?: string, prefix?: string): URL {
	const currentUrl = window.location.href
	const url = new URL(currentUrl)
	if (siteName) {
		const hostParts = url.host.split(".")
		hostParts[0] = siteName
		if (prefix) {
			hostParts.unshift(prefix)
		}
		url.host = hostParts.join(".")
	}
	return url
}
