import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js"
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink, gql } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

let numRequests = 0
let timeoutId: any = null
const updateRequests = (client: any, op: any) => {
	numRequests = op(numRequests)

	if (timeoutId) {
		window.clearTimeout(timeoutId)
	}

	timeoutId = window.setTimeout(() => {
		client.writeQuery({
			query: gql`
				{
					numRequests @client
				}
			`,
			data: { numRequests },
		})
	}, 100)
}
const incRequests = (client: any) => updateRequests(client, (x: any) => x + 1)
const decRequests = (client: any) => updateRequests(client, (x: any) => x - 1)

const cache = new InMemoryCache({ addTypename: false })

function createApolloClient(
	getTokenSilently: (o?: GetTokenSilentlyOptions) => Promise<string | undefined>,
	uri: string
): any {
	const numReqLink = new ApolloLink((operation, forward) => {
		incRequests(client)

		return forward(operation).map(data => {
			decRequests(client)
			return data
		})
	})

	const authLink = setContext(async (_: any, { headers }: any) => {
		const token = await getTokenSilently()
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : null,
			},
		}
	})

	/* const retryLink = new RetryLink({
		delay: {
			initial: 300,
			max: Infinity,
			jitter: true,
		},
		attempts: {
			max: 0,
			retryIf: (error, _operation) => !!error,
		},
	}) */

	const link = ApolloLink.from([
		numReqLink,
		authLink,
		//retryLink,
		createHttpLink({
			uri,
		}),
	])

	const client = new ApolloClient({
		link,
		cache,
		resolvers: {},
	})

	return client
}

export default createApolloClient
